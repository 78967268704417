import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const compraAction = {
  getCompra,
  addCompra,
  getCompraById,
  getXml,
  getPdf,
  getNcmInfo,
  getGtinInfo,
  getCompraArquivo,
  onChangeProps,
  editCompraInfo,
  editComprasDetails,
  editCompraProdutoInfo,
  createCompra,
  refreshComprasProdutos,
  refreshComprasProdutosById,
  editCompraProduto,
  deleteCompraById,
  clear,
  clearNcm,
  clearGtin,
  clearAll,
  clearPedidosComprasProdutos
}

let lastFilterModel = {
  Page: 0,
  Limit: 10,
  Term: '',
  Order: 'dataHora',
  Direction: 'desc',
  IdLoja: 0
}

function getCompra(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    lastFilterModel = filterModel;
    let apiEndpoint = 'compras' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeComprasList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createCompra(payload, redirect, toggle, setModalId) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'compras/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(createCompraInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          if (redirect) {
            dispatch(notIsModalOpen())
            history.push('/compras')
          } else {
            //dispatch(isModalOpen())
            var ret = JSON.parse(response.request.responseText);
            setModalId(ret.key);
            toggle();
          }
        } else {
          // toast.err('Oops! Erro ao cadastrar Pedido de Compra! 😥')
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        //  toast.error('Oops! Erro ao cadastrar Pedido de Compra! 😥')
        return err.response;
      })
  }
}

function refreshComprasProdutosById(id) {
  return (dispatch) => {
    const idToast = toast.loading("Atualizando produtos...")
    let apiEndpoint = 'compras/atualizarCompraNfe/' + id
    crudService.post(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.update(idToast, { render: "Feito! Produtos atualizados com sucesso! 😎", autoClose: 5000, type: "success", isLoading: false });
          dispatch(getCompra(lastFilterModel))
        }
      }).catch((err) => {
        //console.log(err.response);
        toast.dismiss(idToast);
        //  toast.error('Oops! Erro ao cadastrar Pedido de Compra! 😥')
        return err.response;
      })
  }
}

function refreshComprasProdutos() {
  return (dispatch) => {
    const idToast = toast.loading("Atualizando produtos...")
    let apiEndpoint = 'compras/atualizarComprasNfe/true';
    crudService.post(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.update(idToast, { render: "Feito! Produtos atualizados com sucesso! 😎", autoClose: 5000, type: "success", isLoading: false });
          dispatch(getCompra(lastFilterModel))
        }
      }).catch((err) => {
        //console.log(err.response);
        toast.dismiss(idToast);
        //  toast.error('Oops! Erro ao cadastrar Pedido de Compra! 😥')
        return err.response;
      })
  }
}

function editCompraProduto(id, payload) {
  return (dispatch) => {
    let apiEndpoint = 'compras/produto/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(getCompraById(ret.key))
          //dispatch(editComprasDetails(response.data))
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar Compra Produto! 😥')
        return err.response;
      })
  }
}

function getCompraById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'compras/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editComprasDetails(response.data))
      dispatch(notIsLoading())
    })
  }
}

function getPdf(id, chave) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'compras/pdf/' + id
    crudService.get(apiEndpoint, { responseType: 'blob' })
      .then((response) => {

        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = chave + ".pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);

        dispatch(notIsLoading())

      })
  }
}

function getXml(id, chave) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'compras/xml/' + id
    crudService.get(apiEndpoint)
      .then((response) => {

        const blob = new Blob([response.data], { type: "application/xml" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = chave + ".xml";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);

        dispatch(notIsLoading())

      })
  }
}

function getNcmInfo(ncm) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'compras/ncmInfo/' + ncm;
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response?.data) {
          dispatch(success(response.data))
        } else {
          dispatch(clearNcm())
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function success(ncmInfoDetail) {
    return {
      type: 'FETCHED_COMPRA_NCM_INFO_DETAIL',
      ncmInfoDetail: ncmInfoDetail
    }
  }
}

function getGtinInfo(gtin) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'compras/gtinInfo/' + gtin;
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response?.data) {
          dispatch(success(response.data))
        } else {
          dispatch(clearGtin())
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function success(gtinInfoDetail) {
    return {
      type: 'FETCHED_COMPRA_GTIN_INFO_DETAIL',
      gtinInfoDetail: gtinInfoDetail
    }
  }
}

function getCompraArquivo(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'compras/arquivo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        const BOM = '\uFEFF';
        var FileSaver = require('file-saver');
        var blob = new Blob([BOM + response.data], { type: "text/csv;charset=utf-8" });
        FileSaver.saveAs(blob, "compras.csv");

        toast.success('Feito! Download do arquivo realizado com sucesso! 😎')
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editCompraInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'compras/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(updatedCompraInfo())
          if (redirect) {
            dispatch(clear())
            dispatch(notIsLoading())
            dispatch(notIsModalOpen())
            history.push('/compras')
          } else {
            dispatch(isModalOpen())
          }
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao atualizar Pedido de Compra! 😥')
        return err.response;
      })
  }
}

function editCompraProdutoInfo(id, payload, idCompra) {
  return (dispatch) => {
    let apiEndpoint = 'compras/produto/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);

          dispatch(updatedCompraInfo())

          if (idCompra) {
            dispatch(compraAction.getCompraById(idCompra))
          }
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar compra produto! 😥')
        return err.response;
      })
  }
}

function deleteCompraById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'compras/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteComprasDetails())
      dispatch(notIsLoading())
      dispatch(getCompra(lastFilterModel))
    })
  }
}

export function addCompra(payload) {
  return [{ type: 'ADD_COMPRA', compra: payload }, clear()]
}

export function changeComprasList(compra, totalRows) {
  return {
    type: 'FETCHED_ALL_COMPRA',
    compra: compra,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'COMPRA_CLEAR'
  }
}

export function clearNcm() {
  return {
    type: 'COMPRA_CLEAR_NCM'
  }
}

export function clearGtin() {
  return {
    type: 'COMPRA_CLEAR_GTIN'
  }
}

export function clearAll() {
  return {
    type: 'COMPRA_CLEAR_ALL'
  }
}

export function clearPedidosComprasProdutos() {
  return {
    type: 'COMPRA_CLEAR_PEDIDOSCOMPRASPRODUTOS'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'COMPRA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function handleOnChangePropsProdutos(props, value) {
  return {
    type: 'COMPRA_HANDLE_ON_CHANGE_PRODUTOS',
    props: props,
    value: value
  }
}

export function editComprasDetails(compra) {
  return {
    type: 'COMPRA_DETAIL',
    id: compra.id,
    isLoading: compra.isLoading,
    idLoja: compra.idLoja,
    idFornecedorFilial: compra.idFornecedorFilial,
    dataHora: compra.dataHora,
    cnpjFornecedor: compra.cnpjFornecedor,
    nomeFornecedor: compra.nomeFornecedor,
    natureza: compra.natureza,
    valor: compra.valor,
    valorDesconto: compra.valorDesconto,
    linkXml: compra.linkXml,
    linkPdf: compra.linkPdf,
    chave: compra.chave,
    excluido: compra.excluido,
    loja: compra.loja,
    fornecedorFilial: compra.fornecedorFilial,
    comprasProdutos: compra.comprasProdutos
  }
}

export function updatedCompraInfo() {
  return {
    type: 'COMPRA_UPDATED'
  }
}

export function createCompraInfo() {
  return {
    type: 'COMPRA_CREATED_SUCCESSFULLY'
  }
}

export function deleteComprasDetails() {
  return {
    type: 'DELETED_COMPRA_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'COMPRA_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'COMPRA_NOTISLOADING'
  }
}

export function isModalOpen() {
  return {
    type: 'COMPRA_ISMODALOPEN'
  }
}

export function notIsModalOpen() {
  return {
    type: 'COMPRA_NOTISMODALOPEN'
  }
}
