const initialState = {
  isLoading: false,
  totalRegistros: 0,
  relatorio: [],
  page: 0,
  limit: 10,
  order: 'data',
  direction: 'asc',
  term: '',
}

export function relatorio(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_RELATORIO':
      return {
        ...state,
        relatorio: action.relatorio,
        totalRegistros: action.totalRegistros
      }
    case 'RELATORIO_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term
      }
    case 'RELATORIO_CLEAR_ALL':
      return {
        ...state,
        relatorio: []
      }
    case 'RELATORIO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'RELATORIO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'RELATORIO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
